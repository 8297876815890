'use strict';

// Constructor
var LegalAndPrivacy = function() {
  this.name = 'legal-and-privacy';

  jQuery.ajax({
    url: `${process.env.ROOT_API}legal-and-privacy`,
    type: "get",
    success: function(data){
      populateData(data[0]);
      showData();
    },
    error: function() {
      $("#text-section").append("<p style='text-align:center; font-size: 1.25em'>Unable to load content. Please try again.</p>");
      showData();
    }
  }); 
};

/**
 * This function renders the legal and privacy data on to the page
 * 
 * @param {Object} data The legal and privacy object to be rendered.
 */
var populateData = (data) => {
  $("#legal-title").html(data.title);
  $("#text-section").html(data.body);
}

/**
 * Once the data has been rendered, this function removes the loading icon and shows the content on the page.
 */
var showData = () => {
  $('.legal-and-privacy').show();
  $('.footer-bottom').show();
  $('.footer-top').show();
  $('.loader').hide();
}

module.exports = LegalAndPrivacy;