'use strict';

// Constructor
var Teams = function() {
  this.name = 'connect.with.us';

  // get connect-with-us body text from API
  jQuery.ajax({
    url: `${process.env.ROOT_API}connect-with-us`,
    type: "get",
    success: function(data){
        $("#connect-with-us-body").html(data[0].body);
    },
    error: function() {
        $("#connect-with-us-body").append("<p style='text-align:center;'>Unable to load content. Please try again.</p>");
    }
  }); 

  jQuery.ajax({
    url: `${process.env.ROOT_API}connect-us`,
    type: "get",
    success: function(data){
        let cleanedData = organiseDataByLocation(data);
        populateData(cleanedData);
        renderCarousel(cleanedData);
        showData();
    },
    error: function() {
        $("#team-listing").append("<p style='text-align:center; font-size: 1.25em'>Unable to load content. Please try again.</p>");
        showData();
    }
  }); 
};

/**
 * This method re-organises the people data into a dictionary where
 * the key is the location.
 * 
 * @param {Array} data 
 * @returns A Dictionary where the keys are the locations and the item
 * is an array of people based at that location.
 */
var organiseDataByLocation = (data) => {
    let organisedData = {};
    for (let i = 0; i < data.length; i++) {
        let person = data[i];
        if (person.location in organisedData) {
            organisedData[person.location].push(person);
        } else {
            organisedData[person.location] = [person];
        }
    }
    return organisedData;
}

/**
 * This function renders the Team data on to the page.
 * 
 * @param {Array} data The array of team member objects to be rendered containing all team members and their locations.
 */
var populateData = (data) => {
  for (let location in data) {
    let teamMembers = ``;
    for (let i = 0; i < data[location].length; i++) {
        let person = data[location][i];
        let imageArray = data[location][i].field_professional_headshot.split("\"");
        let imageElement = data[location][i].field_professional_headshot;
        let imageSrc = imageElement.replace(/.*src="([^"]*)".*/, '$1').trim();
        let imageAttr = "";
        for (let j = 2; j < imageArray.length; j++) {
            if (j === 7) {
                imageAttr = imageAttr.concat(`"${imageArray[j]}"`);
            } else {
                imageAttr = imageAttr.concat(imageArray[j]);
            }
        }
        teamMembers += 
        `<div class="item">
            <a href="/people.html?id=${person.id}"></a>
            <img class="portrait" src='${process.env.ROOT_URL}${imageSrc}' ${imageAttr}
            <div class="item__overlay">
                <h3>${person.name}</h3>
                <p>${person.position}</p>
                <div class="item__body">
                    <p>${person.short_description}</p>
                    <p>${person.location}</p>
                </div>
            </div>
        </div>
        `;
    }

    $("#team-listing").append(
        `<div class="team-section">
            <h2>${location}</h2>
            <div class="grid">
                ${teamMembers}
            </div>
         </div>`
      )
  }
}

/**
 * This function renders the carousels for each location.
 * 
 */
var renderCarousel = (data) => {
    let elem = document.getElementsByClassName("grid");

    let num = 0;
    for (let i = 0; i < elem.length; i++) {
        elem[i].classList.add(`grid-${num}`);
        num += 1;
    }
    for (let j = 0; j < elem.length; j++) { 
        if ($(window).width() < 500) {
            $(`.grid-${j}`).slick({
                prevArrow: '<button class="slide-arrow prev-arrow"></button>',
                nextArrow: '<button class="slide-arrow next-arrow"></button>',
                slidesToShow: 1,
                slidesToScroll: 1,
                rows: 1,
                arrows: true,
                infinite: true,
                lazyLoad: 'progressive',
                speed: 700,
            });
        } else if ($(window).width() < 700) {
            if (Object.values(data)[j].length > 1) {
                $(`.grid-${j}`).slick({
                    prevArrow: '<button class="slide-arrow prev-arrow"></button>',
                    nextArrow: '<button class="slide-arrow next-arrow"></button>',
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    rows: 1,
                    arrows: true,
                    infinite: true,
                    lazyLoad: 'progressive',
                    speed: 700,
                });
            }
        } else {
            if (Object.values(data)[j].length > 2) {
                $(`.grid-${j}`).slick({
                    prevArrow: '<button class="slide-arrow prev-arrow"></button>',
                    nextArrow: '<button class="slide-arrow next-arrow"></button>',
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    rows: 1,
                    arrows: true,
                    infinite: true,
                    lazyLoad: 'progressive',
                    speed: 700,
                });
            }
            else {
                elem[j].classList.add('no-carousel');
            }
        }   
        
    }
}

/**
 * Once the data has been rendered, this function removes the loading icon and shows the content on the page.
 */
var showData = () => {
    $('.connect').show();
    $('.footer-bottom').show();
    $('.footer-top').show();
    $('.loader').hide();
  }

module.exports = Teams;
