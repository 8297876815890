'use strict';

// Constructor
var DropDownBox = function () {
    $('body').on('click', '.drop-down-box-toggle, .drop-down-box-container .close', function () {
        if ($(this).closest('.drop-down-box-container').find('.drop-down-box').hasClass('overlay-active')) {
            // $(this).removeClass('overlay-active');
            $('.overlay-active').removeClass('overlay-active');
        } else {
            $(this).addClass('overlay-active');
            $(this).closest('.drop-down-box-container').addClass('overlay-active');
            $(this).closest('.drop-down-box-container').find('.drop-down-box').addClass('overlay-active');
            $('.content-overlay').addClass('overlay-active');
            $('body').addClass('overlay-active');
            $(this).closest('.drop-down-box-container').find('.focus-on-open').focus();

        }
    })



};

module.exports = DropDownBox;
