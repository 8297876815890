'use strict';

// Constructor
var Articles = function() {
    this.name = 'articles';

    const queryString = window.location.search;

    // get article data
    jQuery.ajax({
        url: `${window.insolvency_cms_root_api}article-search${queryString}`,
        type: "get",
        success: function(data) {
            populateArticles(data);
            $('.loader').hide();
        },
        error: function() {
            $("#article-list").append("<p style='text-align:center; font-size: 1.25em'>Unable to load content. Please try again.</p>");
            $('.loader').hide();
        }
    });

    // get all tags for filter
    jQuery.ajax({
        url: `${process.env.ROOT_API}tags-list`,
        type: "get",
        success: function(data) {
            populateTagFilter(data);
        }
    });

    // set initial page number to 1
    $("#current-page-number").html("1");

    // disable submitting form when users hit enter key
    $(document).on("keydown", "form", function(e) {
        return e.key != "Enter";
    });
}

/**
 * This function renders 10 articles on to the page.
 *
 * @param {Array} data An array of articles objects retrieved from the Drupal API
 */
var populateArticles = (data) => {
    for (let i = 0; i < data.length && i < 10; i++) {
        // extract source path for image
        let image_raw_array = data[i].image.split("\"");
        // Since 'lazy' in the dom, find url attr dynamically as find it and then
        // returning after it.
        let image_src = image_raw_array.find(function (val, i) {
            if ((typeof image_raw_array[i - 1] !== 'undefined')
                && (image_raw_array[i - 1].indexOf('src=') !== -1)) {
                return true;
            }
            return false;
        });
        let image_attr = "";
        for (let j = 2; j < image_raw_array.length; j++) {
            if (j === 7) {
                image_attr = image_attr.concat(`"${image_raw_array[j]}"`);
            } else {
                image_attr = image_attr.concat(image_raw_array[j]);
            }
        }

        // remove HTML tags from description before displaying
        let description = data[i].body_title.replace(/<[^>]*>?/gm, '');

        // get tags
        let tagsArray = data[i].tags.split(", ");
        let tags = "";
        for (let j = 0; j < tagsArray.length; j++) {
            let tag_data = tagsArray[j].split(">");
            let tag_href = tag_data[0].split("/");
            let tagId = tag_href[tag_href.length - 1].split("\"")[0];
            let tag = tag_data[1].split("<")[0];
            tags += `<a href="/articles.html?tags=${tagId}" class="tag">${tag}</a>`;
        }

        $("#article-list").append(
            `<li class="items">
                <div class="img-container">
                    <a class="image-link" href="/article.details.html?id=${data[i].id}">
                        <img src="${window.insolvency_cms_root_url_no_slash}${image_src}" class="image" ${image_attr}
                    </a>
                </div>
                <a href="/article.details.html?id=${data[i].id}" class="link">
                    <h4 class="title">${data[i].title}</h4>
                </a>
                <div class="description"><p>${description}</p></div>
                <div class="info">
                    <span class="info1">${data[i].published_date}</span>
                    <span class="info2">${data[i].author_name}</span>
                </div>
                <div class="tags">
                    ${tags}
                </div>
            </li>`
        );
    }
}

/**
 * This function renders the tag filters on to the page
 *
 * @param {Array} data An array of tag objects retrieved from the Drupal API
 */
var populateTagFilter = (data) => {
    // add tags
    for (let i = 0; i < data.length; i++) {
        $("#tags-filter").append(
            `<div class="item">
                <input type="radio" name="tags" id=${data[i].tid} value=${data[i].tid} class="radio">
                <label for=${data[i].tid}>${data[i].name}</label>
            </div>`
        );
    }

    // add apply button
    $("#tags-filter").append(
        `<div class="item">
            <input type="submit" value="Apply">
        </div>`
    );

    // grouping radio buttons that do not have the same name
    // i.e. only one of the radio buttons can be selected at a given time
    $(".radio").on("change", function() {
        $(".radio").not(this).prop("checked", false);
    });
}

module.exports = Articles;
