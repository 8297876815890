'use strict';

var Utilities = require('../utilities/utilities');
var utilities = new Utilities();

// Constructor
var CampaignDetails = function() {
  this.name = 'campaign.details';

  let contentTypeLoadApiPath = 'campaign-details';

  utilities.loadPageContent(
    contentTypeLoadApiPath,
    loadPageContentAjaxSuccessCallback,
    loadPageContentAjaxErrorCallback
  );
}

function loadPageContentAjaxSuccessCallback(data) {
  if (data.hasOwnProperty(0)) {
    populateData(data[0]);
    showData();
  }
  else {
    loadPageContentAjaxErrorCallback();
  }
}

function loadPageContentAjaxErrorCallback() {
  showError();
}

var showError = () => {
  $(".container-campaign-details").append("<p style='text-align:center; font-size: 1.25em'>Unable to load content. Please try again.</p>");
  $(".banner").css("display", "none");
  showData();
}

/**
 * This function renders the data object on to the page
 *
 * @param {Object} dataObj A data object retrieved from the backend API
 */
var populateData = (dataObj) => {
  let bodyContent = utilities.handleInlineImages(dataObj.body_description);

  // process video URL in the body content
  bodyContent = bodyContent.replace(/ src=\"\/sites\/default\/files/g, ` src="${process.env.ROOT_URL}sites/default/files`);

  // render content on the page
  $("#campaign-title").html(dataObj.title);
  $(".layout-container.campaign-details .rendered-entity").append(bodyContent);

  // set banner background image
  let image_raw_array = dataObj.image.split("\"");
  // Since 'lazy' in the dom, find url attr dynamically as find it and then
  // returning after it.
  let image_src = image_raw_array.find(function (val, i) {
    if ((typeof image_raw_array[i - 1] !== 'undefined')
      && (image_raw_array[i - 1].indexOf('src=') !== -1)) {
      return true;
    }
    return false;
  });
  let $banner = $(".banner");
  // Banner is optional.
  if (image_src) {
    $banner.attr("style", `background-image:url('${process.env.ROOT_URL}${image_src}')`);
  }
  else {
    $banner.css("display", "none");
  }
}

/**
 * Once the data has been rendered, this function removes the loading icon and shows the content on the page.
 */
var showData = () => {
  $('.campaign-details').show();
  $('.loader').hide();
}

module.exports = CampaignDetails;
