'use strict';

// Constructor
var FooterBottom = function() {
  this.name = 'footer-bottom';

  jQuery.ajax({
    url: `${process.env.ROOT_API}footer`,
    type: "get",
    success: function(data){
      populateData(data[0]);
    },
    error: function() {
      $("#footerbody").html("Unable to load content. Please try again.");
      $("#footerbody").css("font-size", "1.25em");
      $("#footerbody").css("text-align", "center");
    }
  });
};

/**
 * This function renders the footer data on to the page
 * 
 * @param dataObj The footer object to be rendered
 */
var populateData = (dataObj) => {
  $("#footerbody").html(dataObj.body);
}

module.exports = FooterBottom;
