'use strict';

var Utilities = require('../utilities/utilities');
var utilities = new Utilities();

// Constructor
var ArticleDetails = function() {
  this.name = 'article.details';

  let contentTypeLoadApiPath = 'article-details';

  utilities.loadPageContent(
    contentTypeLoadApiPath,
    loadPageContentAjaxSuccessCallback,
    loadPageContentAjaxErrorCallback
  );
}

function loadPageContentAjaxSuccessCallback(data) {
  if (data.hasOwnProperty(0)) {
    populateData(data[0]);
    showData();
  }
  else {
    loadPageContentAjaxErrorCallback();
  }
}

function loadPageContentAjaxErrorCallback() {
  showError();
}

function showError() {
  $("#article-body").append("<p style='text-align:center; font-size: 1.25em'>Unable to load content. Please try again.</p>");
  $(".banner-with-text").css("display", "none");
  $(".sideblock").css("display", "none");
  showData();
}

/**
 * This function renders the data object on to the page
 *
 * @param {Object} dataObj A data object retrieved from the backend API
 */
var populateData = (dataObj) => {
  let bodyContent = utilities.handleInlineImages(dataObj.body_description);

  // process video URL in the body content
  bodyContent = bodyContent.replace(/ src=\"\/sites\/default\/files/g,` src="${process.env.ROOT_URL}sites/default/files`);

  // extract text from html strings
  let subtitle = dataObj.body_title.replace(/(<([^>]+)>)/gi, "");

  let isMobile = window.matchMedia("only screen and (max-width: 767px)").matches;

  if (isMobile) {
    $(".sideblock").prepend(
      `<div class="authors" id="author-section">
          <span class="title">Authors</span>
        </div>
        <hr class="separator">
        <div class="reading-time"></div>
        <div class="date"></div>
        <hr class="separator">
        <ul id="tag-section" class="tags"></ul>
        <div class="share">
          <a onclick="copyLink()">
            <img src="/images/link.svg">
          </a>
          <a onclick="showShareOptions()">
            <img src="/images/share.svg">
          </a>
          <a onclick="window.print()">
            <img src="/images/print.svg">
          </a>
          <hr class="separator">
        </div>`
    );
  } else {
    $(".container-article-details").prepend(
      `<div class="article-info">
        <hr class="separator">
          <div class="horizontal">
            <i class="icon-calendar"></i>
            <div class="date"></div>
            <img class="clock-icon" src="/images/clock.svg">
            <div class="reading-time"></div>
            <div class="share">
              <a onclick="copyLink()">
                <img src="/images/link.svg">
              </a>
              <a onclick="showShareOptions()">
                <img src="/images/share.svg">
              </a>
              <a onclick="window.print()">
                <img src="/images/print.svg">
              </a>
            </div>
          </div>
        <hr class="separator">
      </div>`
    );
    $(".sideblock").append(
      `<div class="authors" id="author-section">
          <span class="title">Authors</span>
      </div>`
    );
  }
  // render content on the page
  $("#article-title").html(dataObj.title);
  $("#subtitle").append(subtitle);
  $("#article-body").append(bodyContent);
  $(".reading-time").html(dataObj.read_time);
  $(".date").html(dataObj.published_date);

  // set banner background image
  let image_raw_array = dataObj.image.split("\"");
  // Since 'lazy' in the dom, find url attr dynamically as find it and then
  // returning after it.
  let image_src = image_raw_array.find(function (val, i) {
    if ((typeof image_raw_array[i - 1] !== 'undefined')
      && (image_raw_array[i - 1].indexOf('src=') !== -1)) {
      return true;
    }
    return false;
  });
  $(".banner-with-text").attr("style", `background-image:url('${process.env.ROOT_URL}${image_src}')`);

  // populating authors
  let authorArray = dataObj.author_name.split(", ");
  let authorPosArray = dataObj.author_designation.split(", ");
  let authorImageArray = dataObj.author_image.split(", ");
  for (let i = 0; i < authorArray.length; i++) {
    $("#author-section").append(
      `<div class="author">
        <img src="${process.env.ROOT_URL}${authorImageArray[i]}" class="avatar" alt="Avatar">
        <span class="name">${authorArray[i]}</span>
        <span class="creds">${authorPosArray[i]}</span>
      </div>`
    );
  }

  // populating tags
  let tagsArray = dataObj.tags.split(", ");
  for (let j = 0; j < tagsArray.length; j++) {
      let tag_data = tagsArray[j].split(">");
      let tag_href = tag_data[0].split("/");
      let tagId = tag_href[tag_href.length - 1].split("\"")[0];
      let tag = tag_data[1].split("<")[0];
      $("#tag-section").append(
        `<li>
          <a href="/articles.html?tags=${tagId}" class="tag">${tag}</a>
        </li>`
      );
  }

  // process accordion title and content in rich text
  var accordionTitles = [];
  var accordionTitleIndex = -1;
  var accordionContents = [];
  var accordionContentStatus = 0;
  var articleParagraphs = $("#article-body").children();
  for( var i = 0; i < articleParagraphs.length; i++){
    if($(articleParagraphs[i]).hasClass("accordion-title")){
      accordionTitleIndex++;
      accordionTitles.push(articleParagraphs[i]);
    }else if($(articleParagraphs[i]).hasClass("accordion-content")){
      if(typeof(accordionContents[accordionTitleIndex]) == 'undefined'){ accordionContents.push([]); }
      if(accordionTitleIndex < 0) accordionTitleIndex = 0;
      accordionContents[accordionTitleIndex].push(articleParagraphs[i]);
    }else if($(articleParagraphs[i]).hasClass("accordion-content-start")){
      if(typeof(accordionContents[accordionTitleIndex]) == 'undefined'){ accordionContents.push([]); }
      if(accordionTitleIndex < 0) accordionTitleIndex = 0;
      accordionContents[accordionTitleIndex].push(articleParagraphs[i]);
      accordionContentStatus = 1;
    }else if($(articleParagraphs[i]).hasClass("accordion-content-end")){
      if(typeof(accordionContents[accordionTitleIndex]) == 'undefined'){ accordionContents.push([]); }
      accordionContents[accordionTitleIndex].push(articleParagraphs[i]);
      accordionContentStatus = 0;
    }else if(accordionContentStatus == 1){
      if(typeof(accordionContents[accordionTitleIndex]) == 'undefined'){ accordionContents.push([]); }
      accordionContents[accordionTitleIndex].push(articleParagraphs[i]);
    }
  }
  for( var i = 0; i < accordionTitles.length; i++){
    if(accordionTitles[i].nextElementSibling!=null){
      var accordionHolder = document.createElement("div"); accordionHolder.className = "accordion-holder";
      var accordionContentHolder = document.createElement("div"); accordionContentHolder.className = "accordion-content-holder";
      accordionTitles[i].parentNode.insertBefore(accordionHolder,accordionTitles[i].nextElementSibling);
      accordionHolder.appendChild(accordionTitles[i]);
      accordionHolder.appendChild(accordionContentHolder);
      if(typeof(accordionContents[i]) != 'undefined'){
        for( var j = 0; j < accordionContents[i].length; j++){
          accordionContentHolder.appendChild(accordionContents[i][j]);
        }
      }
    }
  }

  // add logic of open and collapse accordions
  for ( i = 0; i < accordionTitles.length; i++) {
      accordionTitles[i].addEventListener('click', function() {
        this.classList.toggle('accordion-title-open');
        this.nextSibling.classList.toggle('active');
      });
  }
}

/**
 * Once the data has been rendered, this function removes the loading icon and shows the content on the page.
 */
var showData = () => {
  $('.article-details').show();
  $('.footer-bottom').show();
  $('.footer-top').show();
  $('.loader').hide();
}

module.exports = ArticleDetails;
