'use strict';

// Constructor
var HomepageBanner = function() {
  $('.homepage-banner .left, .homepage-banner .right').matchHeight();

  jQuery.ajax({
    url: `${process.env.ROOT_API}homepage-content`,
    type: "get",
    success: function(data){
        populateData(data[0]);
    },
    error: function() {
      $("#home-body").append("<p style='text-align:center'>Unable to load banner content. Please try again.</p>");
      $(".wrapper").css("display", "none");
      showData();
    }
  });
  jQuery.ajax({
    url: `${process.env.ROOT_API}article-list`,
    type: "get",
    success: function(data){
        populateFeatureArticles(data);
        renderCarousel();
        showData();
    },
    error: function() {
      $("#home-body").append("<p style='text-align:center'>Unable to load banner content. Please try again.</p>");
      $(".wrapper").css("display", "none");
      showData();
    }
  });
}

/**
 * This function renders the featured articles carousel.
 *
 */
var renderCarousel = () => {
  setTimeout(function(){
    $('.carousel-container').attr("style","display: block");

    $('.article-carousel').slick({
      dots: true,
      infinite: true,
      speed: 700,
      autoplay: false,
      arrows: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      rows: 1,
      lazyLoad: 'progressive',
      dotsClass: 'slick-dots',
      prevArrow: '<button class="slide-arrow prev-arrow"></button>',
      nextArrow: '<button class="slide-arrow next-arrow"></button>',
      responsive: [
          {
              breakpoint: 600,
              settings: {
                  initialSlide: 1,
              }
          }
      ]
    });
  }, 0);
  $(window).on('resize', function () {
      $('.homepage .homepage-banner .container-outer .wrapper ul').slick('resize');
  });
}

/**
 * This function renders the featured articles in the carousel
 *
 * @param {Object} data The featured articles data to be rendered.
 */
var populateFeatureArticles = (data) => {
  let max_articles = 3;
  let i = 0;

  while (i < data.length && i < max_articles) {
    let image_raw_array = data[i].image.split("\"");
    // Since 'lazy' in the dom, find url attr dynamically as find it and then
    // returning after it.
    let image_src = image_raw_array.find(function (val, i) {
      if ((typeof image_raw_array[i - 1] !== 'undefined')
        && (image_raw_array[i - 1].indexOf('src=') !== -1)) {
        return true;
      }
      return false;
    });
    let image_attr = "";
    let slide = "#featured-article" + (i+1).toString();

    let articleSubtitle = data[i].body_title.replace(/<[^>]+>/g, '');

    for (let j = 2; j < image_raw_array.length; j++) {
      if (j === 7) {
          image_attr = image_attr.concat(`"${image_raw_array[j]}"`);
      } else {
          image_attr = image_attr.concat(image_raw_array[j]);
      }
    }

    let isMobile = window.matchMedia("only screen and (max-width: 800px)").matches;

    if (isMobile) {
      $(slide).append(
        `<a href="/article.details.html?id=${data[i].id}" class="mobileLink">
          <div class="mobile-card">
            <div class="mobile-card-img">
              <img src="${process.env.ROOT_URL}${image_src}" class="mobile-image">
            </div>
            <div class="mobile-card-info">
              <h4 class="mobile-article-type">Case Study</h4>
              <h4 class="mobile-card-title">
                ${data[i].title}
              </h4>
            </div>
          </div>
        </a>
        `
      );

    } else {
      $(slide).append(
        `<div class="left">
            <h4 class="featured-type">Case Study</h4>
            </br>
            <h4 class="featured-title">
              <a href="/article.details.html?id=${data[i].id}" class="titleLink">${data[i].title}</a>
            </h4>
            <p>${articleSubtitle}</p>
            <p>${data[i].read_time}</p>
          </div>
          <div class="carousel-img-container">
            <a href="/article.details.html?id=${data[i].id}">
              <img src="${process.env.ROOT_URL}${image_src}" class="carousel-image">
            </a>
          </div>
        `
      );
    }

    i++;
  }
}

/**
 * This function renders the homepage banner content on to the page.
 *
 * @param {Object} dataObj The homepage banner data object to be rendered.
 */
var populateData = (dataObj) => {
  $("#home-title").html(dataObj.title);
  $("#home-body").html(dataObj.body);
  $("#banner-text").html(dataObj.banner_text);
  $("#banner-small-text").html(dataObj.banner_small_text);
}

/**
 * Once the data has been rendered, this function removes the loading icon and shows the content on the page.
 */
 var showData = () => {
  $('.homepage-banner').show();
  $('.our-latest-thinking').show();
  $('.how-can-we-help').show();
  $('.the-team').show();
  $('.footer-bottom').show();
  $('.footer-top').show();
  $('.loader').hide();
}

module.exports = HomepageBanner;
