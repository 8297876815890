'use strict';

// Constructor
var Utilities = function () {
  this.name = 'utilities';

  this.api_path_get_node_ids_by_path = 'get-node-ids-by-path';

  this.loadPageContent = (contentTypeLoadApiPath, ajaxSuccessCallbackNew, ajaxErrorCallbackNew) => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    // Get Drupal node ID from URL.
    var drupalMatchRecord = null;
    var drupalNodeId = null;
    // Prefer path, if it's there.
    if (drupalMatchRecord = this.get_page_metadata_by_current_path_param()) {
      drupalNodeId = drupalMatchRecord.id;
    }
    if (!drupalNodeId) {
      drupalNodeId = urlParams.get('id');
    }

    // Retrieve content from backend API and render onto the page.
    jQuery.ajax({
      url: `${process.env.ROOT_API}${contentTypeLoadApiPath}?id=${drupalNodeId}`,
      type: "get",
      success: function (data) {
        ajaxSuccessCallbackNew(data);

      },
      error: function () {
        ajaxErrorCallbackNew();
      }
    });
  }

  /**
   * Get the page metadat record form the current path parameter.
   *
   * @returns null|array the record of NULL.
   */
  this.get_page_metadata_by_current_path_param = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    return this.get_page_metadata_by_path(urlParams.get('path'));
  }

  /**
   * Get the page metadat record form the current path parameter.
   *
   * @returns null|array the record of NULL.
   */
  this.get_page_metadata_by_path = (drupalNodePath) => {
    var drupalMatchRecord = null;

    jQuery.ajax({
      url: `${process.env.ROOT_API}${this.api_path_get_node_ids_by_path}`,
      type: "get",
      success: function (records) {
        for (let i = 0; i < records.length; i++) {
          if (records[i].path === drupalNodePath) {
            drupalMatchRecord = records[i];
            break;
          }
        }
      },
      error: function () {
      },
      async: false
    });
    return drupalMatchRecord;
  }

  /**
   * Get redirected page path or the current one if not redirected.
   *
   * @returns string
   *   The page path, for example "aged-care".
   */
  this.get_page_path_from_maybe_current_redirected_path = () => {
    // Get the "aged-care" path without forward slash prefix for example in
    // site.com/aged-care.
    let current_or_matched_path = window.location.pathname.substring(1);

    jQuery.ajax({
      url: `${process.env.ROOT_API}redirects`,
      type: "get",
      success: function (records) {
        for (let i = 0; i < records.length; i++) {
          if (records[i].from_path === current_or_matched_path) {
            current_or_matched_path = records[i].to_path;
            break;
          }
        }
      },
      error: function () {
      },
      async: false
    });
    return current_or_matched_path;
  }

  /**
  * This function handles the HTML structure and CSS styling of images in the DOM
  *
  * @param {Object} dataObj A data object retrieved from the backend API
  */
  this.handleInlineImages = (html) => {
    let tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;

    let source_elements = tempDiv.getElementsByTagName('source');
    for (let i = 0; i < source_elements.length; i++) {
      if (source_elements[i].srcset) {
        source_elements[i].srcset = source_elements[i].srcset.replace(/\/sites\/default\/files/g, `${process.env.ROOT_URL}sites/default/files`);
      }
    }

    // Change the src URL of all inline images
    let images = tempDiv.getElementsByTagName('img');
    for (let i = 0; i < images.length; i++) {
      let inlineImage = images[i].src.split('/');
      let imageClass = "";
      inlineImage[2] = `${process.env.ROOT_URL_NO_SLASH}`;
      inlineImage = inlineImage.slice(2).join('/');
      tempDiv.getElementsByTagName('img')[i].src = inlineImage;

      // Responsive images.
      if (images[i].srcset) {
        images[i].srcset = images[i].srcset.replace(/\/sites\/default\/files/g, `${process.env.ROOT_URL}sites/default/files`);
      }

      // add CSS styling to match the styling in the CMS
      if (tempDiv.getElementsByTagName('img')[i].parentElement.tagName.toLowerCase() === "figure") {
        // Handle images WITH captions
        imageClass = tempDiv.getElementsByTagName('img')[i].parentElement.className;
        if (imageClass.includes("align-right")) {
          tempDiv.getElementsByTagName('img')[i].parentElement.style.cssText += ("float: right; margin-left: 40px; margin-top: 15px; margin-bottom: 15px;");
        } else if (imageClass.includes("align-left")) {
          tempDiv.getElementsByTagName('img')[i].parentElement.style.cssText += ("float: left; margin-right: 40px; margin-top: 15px; margin-bottom: 15px;");
        } else if (imageClass.includes("align-center")) {
          tempDiv.getElementsByTagName('img')[i].parentElement.style.cssText += ("margin: auto; display: block;")
        }
        tempDiv.getElementsByTagName('img')[i].parentElement.style.cssText += ("text-align: center;")
      } else {
        // Handle images WITHOUT captions
        imageClass = tempDiv.getElementsByTagName('img')[i].className;
        if (imageClass.includes("align-right")) {
          tempDiv.getElementsByTagName('img')[i].style.cssText += ("float: right; margin-left: 40px; margin-top: 15px; margin-bottom: 15px;");
        } else if (imageClass.includes("align-left")) {
          tempDiv.getElementsByTagName('img')[i].style.cssText += ("float: left; margin-right: 40px; margin-top: 15px; margin-bottom: 15px;");
        } else if (imageClass.includes("align-center")) {
          tempDiv.getElementsByTagName('img')[i].style.cssText += ("margin: auto; display: block;")
        }
      }
    }
    return tempDiv.innerHTML;
  }
}


module.exports = Utilities;
