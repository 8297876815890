'use strict';

// Constructor
var Redirect = function() {
  // if($('.redirect-to-public').length > 0) {
  //   window.location.href = '/portal/'
  // }
};

module.exports = Redirect;
