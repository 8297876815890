'use strict';

var Utilities = require('../utilities/utilities');
var utilities = new Utilities();

// Constructor
var ErrorPage = function() {
  this.name = '404 error';

  // See if there is redirect in place first.
  const current_or_matched_path = utilities.get_page_path_from_maybe_current_redirected_path();
  let template_mapping = {
    // Drupal content type to html file.
    'article': 'article.details.html',
    'campaign': 'campaign.details.html',
    'page': 'people.details.html'
  };

  // Get Drupal page by Id and redirect the handling template.
  var drupalMatchRecord = null;
  var is_page_redirected = false;
  // Pass the "aged-care" path without forward slash prefix for example in
  // site.com/aged-care.
  if (drupalMatchRecord = utilities.get_page_metadata_by_path(current_or_matched_path)) {
    let html_page_file = null;
    if (html_page_file = template_mapping[drupalMatchRecord.content_type]) {
      window.location.href = `/${html_page_file}?path=${drupalMatchRecord.path}`;
      is_page_redirected = true;
    }
  }
  if (!is_page_redirected) {
    $('.loader').hide();
  }
};

module.exports = ErrorPage;
