'use strict';

// Constructor
var ContentOverlay = function () {
    $('.content-overlay').click(function () {
        $('.overlay-active').removeClass('overlay-active');
        $('.overlay-active-filters').removeClass('overlay-active-filters');
    })

    $(document).keyup(function (e) {
        if (e.key === "Escape") { // escape key maps to keycode `27`
            $('.overlay-active').removeClass('overlay-active');
            $('.overlay-active-filters').removeClass('overlay-active-filters'); 

        }
    });
};

module.exports = ContentOverlay;
