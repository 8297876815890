'use strict';

// Constructor
var CurrentCases = function() {
	this.name = 'current.cases';

	// get current cases data
	jQuery.ajax({
		url: `${process.env.ROOT_INSOL6_API}PublicJobsForPractice/ey`,
		type: "get",
		success: function(data) {
			setSessionStorage(data);
			caseData = data;
			populateFilters();
			populateCases();
			setPageButtonVisibility('cases');
			$('.loader').hide();
		},
		error: function() {
			$("#cases-list").append("<p style='text-align:center'>Unable to load content. Please try again.</p>");
			$('.loader').hide();
		}
	});
	jQuery.ajax({
		url: `${process.env.ROOT_API}disclaimer`,
		type: "get",
		success: function(data){
			populateDisclaimer(data[0]);
		}
	});

	// set initial page number to 1
	$("#current-page-number").html("1");

	// initialise all checkboxes as checked
	$("input[type='checkbox']").each(function(){
		$(this).prop("checked", true);
	});

	// check/uncheck all checkboxes when Select All option is checked
	$("#alllocations").on("change", function() {
		var checked = $(this).is(':checked');
		if (checked) {
			$(".location-checkbox").each(function(){
				$(this).prop("checked",true);
			});
		}
		else {
			$(".location-checkbox").each(function(){
				$(this).prop("checked",false);
			});
		}
	});

	$("#alltypes").on("change", function() {
		var checked = $(this).is(':checked');
		if (checked) {
			$(".type-checkbox").each(function(){
				$(this).prop("checked",true);
			});
		}
		else {
			$(".type-checkbox").each(function(){
				$(this).prop("checked",false);
			});
		}
	});
};

/**
 * This function stores the data object (previously retrieved from Insol6 API) into session storage.
 * 
 * @param {Object} data The data object being stored in session storage.
 */
var setSessionStorage = (data) => {
	sessionStorage.setItem("data", JSON.stringify(data));
}

/**
 * This function gets the data stored in session storage and parses it as a JSON object
 * 
 * @param {String} key Corresponds with the value (data) that is being retrieved
 */
var getSessionStorage = (key) => {
	return JSON.parse(sessionStorage.getItem(key));
}

/**
 * This function renders 10 cases on to the page.
 * 
 */
var populateCases = () => {
	// get data from session storage
	let data = getSessionStorage("data");
	let locationArray = document.getElementsByClassName("info1");
	let typeArray = document.getElementsByClassName("info2");

	// display a page of cases
    for (let i = 0; i < data.length && i < 10; i++) {
		$("#cases-list").append(
			`<li class="items all ${data[i].addressState}">
				<a class="link" onclick="disclaimer(${data[i].jobId})">
					<h4 class="title">${data[i].jobname}</h4>
					<div class="info">
						<span class="info1">${data[i].addressState}, ${data[i].addressCountry}</span>
						<span class="info2">${data[i].jobTypeText}</span>
					</div>
				</a>
			</li>`
		);
    }

	// prevent null values from being displayed
	for (let i = 0; i < locationArray.length; i++) {
		if (!data[i].addressState || !data[i].addressCountry) {
			locationArray[i].style.display = "none";
		}
		if (!data[i].jobTypeText) {
			typeArray[i].style.display = "none";
		}
	}
}

/**
 * This function renders the disclaimer popup.
 * 
 * @param {Object} dataObj The disclaimer object to be rendered.
 */
var populateDisclaimer = (dataObj) => {
	$("#disclaimertitle").html(dataObj.title);
	$("#disclaimerbody").html(dataObj.body);
}

/**
 * This function renders the category filters (location and appointment type)
 * 
 */
var populateFilters = () => {
	// add location filters
	let data = getSessionStorage("data");
	let locations = [];

	// add each location checkbox
	for (let i = 0; i < data.length; i++) {
		if (data[i].addressState && !locations.includes(data[i].addressState)) {
			locations.push(data[i].addressState);
			$("#location-filters").append(
				`<div class="item">
					<input type="checkbox" class="location-checkbox" id="${data[i].addressState}" value="${data[i].addressState}" onchange="checkUncheck('location')" checked>
					<label for="${data[i].addressState}">${data[i].addressState}</label>
				</div>`
			);
		}		
  	}
	// add the apply button
	$("#location-filters").append(
		`<div class="item">
			<input type="submit" onclick="hideFilterBox()" value="Apply">
		</div>`
	);
	
	// add appointment type filters
	let appointmentTypes = [];
	for (let i = 0; i < data.length; i++) {
		let jobType = data[i].jobTypeText.replace(/\s+/g, '-').toLowerCase();
		if (jobType && !appointmentTypes.includes(jobType)) {
			appointmentTypes.push(jobType);
			$("#appointment-filters").append(
				`<div class="item">
					<input type="checkbox" class="type-checkbox" id="${jobType}" value="${data[i].jobTypeText}" onchange="checkUncheck('type')" checked>
					<label for="${jobType}">${data[i].jobTypeText}</label>
				</div>`
			);
		}
	}

	// add the apply button
	$("#appointment-filters").append(
		`<div class="item">
			<input type="submit" onclick="hideFilterBox()" value="Apply">
		</div>`
	);
}

module.exports = CurrentCases;
