'use strict';

// Constructor
var FormalInsolvency = function() {
  this.name = 'formal-insolvency';

  jQuery.ajax({
    url: `${process.env.ROOT_API}homepage-content`,
    type: "get",
    success: function(data){
      populateData(data[0]);
    },
    error: function() {
      $("#formal-insolvency-description").append("<p style='text-align:center'>Unable to load content. Please try again.</p>");
      showData();
    }
  });
};

/**
 * This function renders the 'Formal insolvency' content on to the page.
 * 
 * @param {Array} data The 'Formal insolvency' array to be rendered.
 */
var populateData = (data) => {
  $("#formal-insolvency-title").html(data.formal_insolvency_title);
  $("#formal-insolvency-description").html(data.formal_insolvency_description);

  let formal_insolvency_tab_titles = data.formal_insolvency_tab_title.split(";;");
  let formal_insolvency_tab_descriptions = data.formal_insolvency_tab_description.split(";;,");
  let isMobile = window.matchMedia("only screen and (max-width: 920px)").matches;

  // Remove trailing ;; from last element in array
  let last_elem = formal_insolvency_tab_descriptions[formal_insolvency_tab_descriptions.length - 1];
  if (last_elem.substring(last_elem.length - 2) === ";;") {
    formal_insolvency_tab_descriptions[formal_insolvency_tab_descriptions.length - 1] = last_elem.slice(0, -2);
  }

  // Render content
  for (let i = 0; i < formal_insolvency_tab_titles.length; i++) {
  	let formal_insolvency_tabs_checked = (i==0)?" checked":"";
      $("#formal-insolvency-tabs").append(
      		`<input type="radio" id="tab${i+1}" name="tab-control"${formal_insolvency_tabs_checked}>`
      );
  }
  
  if (isMobile) {
    $("#formal-insolvency-tabs").append(`<ul id="mobile-accordion"></ul>`);
    for (let i = 0; i < formal_insolvency_tab_titles.length; i++) {
      let formal_insolvency_tab_description_value = formal_insolvency_tab_descriptions[i].replace(/ src=\"\/sites\/default\/files/g,` src="${process.env.ROOT_URL}sites/default/files`); // replace all embedded image to absolute URL
      
      let tab_title_obj = document.createElement("h3"); tab_title_obj.className = "accordion-title"; tab_title_obj.textContent = formal_insolvency_tab_titles[i];
      let accordionHolder = document.createElement("div"); accordionHolder.className = "accordion-holder";
      let accordionContentHolder = document.createElement("div"); accordionContentHolder.className = "accordion-content-holder";
      let tab_description_obj = document.createElement("p"); tab_description_obj.className = "accordion-content"; tab_description_obj.innerHTML = formal_insolvency_tab_description_value;
      
      $("#mobile-accordion").append(accordionHolder);
      accordionHolder.appendChild(tab_title_obj);
      accordionHolder.appendChild(accordionContentHolder);
      accordionContentHolder.appendChild(tab_description_obj);
    }
  } else {
    $("#formal-insolvency-tabs").append(`<ul id="formal-insolvency-tab-title" class="tab-list"></ul>`);
    $("#formal-insolvency-tabs").append(`<div id="formal-insolvency-tab-description" class="content"></div>`);
    for (let i = 0; i < formal_insolvency_tab_titles.length; i++) {
      let formal_insolvency_tab_title_value = formal_insolvency_tab_titles[i];
      let formal_insolvency_tab_description_value = formal_insolvency_tab_descriptions[i].replace(/ src=\"\/sites\/default\/files/g,` src="${process.env.ROOT_URL}sites/default/files`); // replace all embedded image to absolute URL
      $("#formal-insolvency-tab-title").append(
        `<li class="tab" title="${formal_insolvency_tab_title_value}">
          <label for="tab${i+1}" role="button">
            <span>${formal_insolvency_tab_title_value}</span>
          </label> 
        </li>`);
      $("#formal-insolvency-tab-description").append(
        `<section> 
          ${formal_insolvency_tab_description_value}
        </section>`);
    }
  }

  // Add link buttons
  let formal_insolvency_link = data.formal_insolvency_link.split(";;");
  $("#formal-insolvency-link").append(`<div id="formal-insolvency-link-tmp">`);
  $("#formal-insolvency-link-tmp").hide();
  for (let i = 0; i < formal_insolvency_link.length; i++) {
    $("#formal-insolvency-link-tmp").append(formal_insolvency_link[i]);
    let link_title = $("#formal-insolvency-link-tmp").children().last().find(".link-title").text();
    let link_url = $("#formal-insolvency-link-tmp").children().last().find(".link-url").text();
    $("#formal-insolvency-link").append(`<button type="button" onclick="window.open('${link_url}', '_blank')" class="button insol6-btn"><span class="btn-label"><strong>${link_title}</strong></span></button>`);
  }

  // add logic of open and collapse accordions
  let accordionTitles = document.getElementsByClassName('accordion-title');

  for ( i = 0; i < accordionTitles.length; i++) {
      accordionTitles[i].addEventListener('click', function() {
        this.classList.toggle('accordion-title-open');
        this.nextSibling.classList.toggle('active');
      });
  }
}

module.exports = FormalInsolvency;