'use strict';

var Utilities = require('../utilities/utilities');
var utilities = new Utilities();

// Constructor
var PeopleDetails = function() {
  this.name = 'people.details';

  let contentTypeLoadApiPath = 'bio-details';

  utilities.loadPageContent(
    contentTypeLoadApiPath,
    loadPageContentAjaxSuccessCallback,
    loadPageContentAjaxErrorCallback
  );
}

function loadPageContentAjaxSuccessCallback(data) {
  populateData(data[0]);
  showData();
}

function loadPageContentAjaxErrorCallback() {
  // Noop, not sure why.
}


/**
 * This function renders person bio data on to the page
 *
 * @param {Object} data The team member object to be rendered.
 */
function populateData(dataObj) {
  // add main content
  $("#text").append(
    `<h1 id="person-name">${dataObj.name}</h1>
      <h4 class="position">${dataObj.position}</h4>
      <h4 class="short-description">${dataObj.short_description}</h4>
      <p id="body">${dataObj.body}</p>
      <h3 id="people-help-title">${dataObj.help_title}</h3>
      <p id="people-help-description">${dataObj.help_description}</p>
    `
  );

  // add sideblock content
  $("#side").append(
    `<ul id="tag-section" class="tags"><span class="title">Area of focus</span></ul>
      <hr class="seperator">
      <div class="address"><span class="title">Office</span><span id="person-location">${dataObj.location}</span></div>
      <div id="contacts" class="share"></div>
      <hr class="separator">
    `
  );

  // position the profile image in either main textblock or sideblock depending on screen size
  let isMobile = window.matchMedia("only screen and (max-width: 767px)").matches;

  if (isMobile) {
    $("#text").prepend(`<div id="person-image" class="image"></div>`);
  }
  else {
    $("#side").prepend(`<div id="person-image" class="image"></div>`);
  }

  // set image
  let imageElement = dataObj.image;
  let imageSrc = imageElement.replace(/.*src="([^"]*)".*/, '$1').trim();

  $("#person-image").attr("style", `background-image:url('${process.env.ROOT_URL}${imageSrc}')`);

  // populating tags
  let tagArray = dataObj.tags.split(", ");
  for (let i = 0; i < tagArray.length; i++) {
    $('#tag-section').append(
      `<li>
        <a class="tag">${tagArray[i]}</a>
      </li>`
    );
  }

  // add contact details
  if (dataObj.linkedin_url || dataObj.email || dataObj.field_phone) {
    $("#contacts").append('<span class="title">Contact</span>');
  } else {
    $("#contacts").hide();
  }

  if (dataObj.email) {
    $("#contacts").append(
      `<a href="mailto:${dataObj.email}" target="_blank">
        <img src="/images/email.svg">
      </a>`
    );
  }

  if (dataObj.field_phone) {
    $("#contacts").append(`
      <a class="tooltip" href="tel:${dataObj.field_phone}">
        <img src="/images/phone.svg">
        <span class="tooltiptext"><p>${dataObj.field_phone}</p></span>
      </a>`
    );
  }

  if (dataObj.linkedin_url) {
    $("#contacts").append(`
      <a href="${dataObj.linkedin_url}" target="_blank">
        <img src="/images/linkedin.svg">
      </a>`
    );
  }
}

function showData() {
  $('.people-details').show();
  $('.footer-bottom').show();
  $('.footer-top').show();
  $('.loader').hide();
}

module.exports = PeopleDetails;
