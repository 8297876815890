'use strict';

// Constructor.
var FixLinks = function() {

  function FixLinksFunction () {
    // Turn these links in to CMS absolute links.
    $("a[href^='/sites/default/files']").each(function (){ 
      var $this = $(this);
      $this.attr('href', window.insolvency_cms_root_url_no_slash + $this.attr('href'));
    });
  }

  // React to Ajax events.
  if (!FixLinks.has_already_run) {
    $(document).ajaxComplete(function( event, xhr, settings ) {
      FixLinksFunction();
    });
    FixLinks.has_already_run = true;
  }
  
  // First time run.
  FixLinksFunction();
};
// Initialise static variable.
FixLinks.has_already_run = false;

module.exports = FixLinks;
