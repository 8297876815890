'use strict';

// Constructor
var Login = function () {
    $('.creditor-login-cta').click(function ($event) {
        $event.preventDefault();
        $('.overlay-active').removeClass('overlay-active');
        $('.login').toggleClass('overlay-active');

        $('.content-overlay').addClass('overlay-active');
        $('body').addClass('overlay-active');
        $(this).addClass('overlay-active');
    })

    $('.login .close').click(function ($event) {
        $event.preventDefault();
        $('.overlay-active').removeClass('overlay-active');
    })

    $('.login form').submit(function ($event) {
        $event.preventDefault();
        if ($('.login form input.email').val() && $('.login form input.password').val()) {
            if ($('.login form input.email').val() === 'ato') {
                window.location.href = '/secured/ato/'
            } else if ($('.login form input.email').val() === 'feg') {
                window.location.href = '/secured/feg/'
            } else if ($('.login form input.email').val() === 'any') {
                window.location.href = 'https://ey.creditors.accountants/Account/Login'
            } else {
                $('.login .error').addClass('display');
                setTimeout(function () {
                    $('.login .error').removeClass('display');

                }, 5000)
            }

        } else {
            $('.login .error').addClass('display');
            setTimeout(function () {
                $('.login .error').removeClass('display');

            }, 5000)
        }


    })
};

module.exports = Login;
