'use strict';

// Constructor
var FooterTop = function() {
  this.name = 'footer-top';

  jQuery.ajax({
    url: `${process.env.ROOT_API}contact-us`,
    type: "get",
    success: function(data){
      populateData(data[0]);
    },
    error: function() {
      $("#contactusbody").html("Unable to load content. Please try again.");
      $("#contactusbody").css("color", "white");
      $("#contactusbody").css("font-size", "1.25em");
    }
  });
};

/**
 * This function renders the footer data on to the page
 * 
 * @param dataObj The footer object to be rendered
 */
var populateData = (dataObj) => {
  $("#contactusbody").html(dataObj.body);
}

module.exports = FooterTop;
