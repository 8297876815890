'use strict';

// Constructor
var CaseDetails = function() {
  this.name = 'case.details';

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const caseId = urlParams.get('id');

  // if session storage is empty, get data from API and save in session storage
  if (!sessionStorage['data']) {
    jQuery.ajax({
      url: `${process.env.ROOT_INSOL6_API}PublicJobsForPractice/ey/`,
      type: "get",
      success: function(casesData) {
        setSessionStorage(casesData);
        populateData(caseId);
        showData();
      },
      error: function() {
        showError();
        showData();
      }
    });
  }
  else {
    populateData(caseId);
    showData();
  }

  jQuery.ajax({
    url: `${process.env.ROOT_API}case-details?id=${caseId}`,
    type: "get",
    success: function(data) {
      if (data[0].field_case_document) populateDocuments(data[0]);
    },
    error: function() {
      showError();
      showData();
    }
  });
}

var showError= () => {
  $("#abn").append("<p style='text-align:center; font-size: 1.25em'>Unable to load content. Please try again.</p>");
  $("#company-title").css("display", "none");
  $("#abn-title").css("display", "none");
  $("#bg-info-title").css("display", "none");
  $(".sideblock").css("display", "none");
}

/**
 * This function gets a case object by its id.
 * 
 * @param {String} id The id of the case
 * @returns A case object
 */
var getCaseById = (id) => {
  let data = getSessionStorage("data");
  for (let i = 0; i < data.length; i++) {
    if (data[i].jobId == id) {
      return data[i];
    }
  }
}

/**
 * This function stores the data object (previously retrieved from Insol6 API) into session storage.
 * 
 * @param {Object} data The data object being stored in session storage.
 */
var setSessionStorage = (data) => {
	sessionStorage.setItem("data", JSON.stringify(data));
}

/**
 * This function gets the data stored in session storage and parses it as a JSON object
 * 
 * @param {String} key Corresponds with the value (data) that is being retrieved
 */
var getSessionStorage = (key) => {
  return JSON.parse(sessionStorage.getItem(key));
}

/**
 * This function renders the case data on to the page.
 * 
 * @param {String} caseId The id of the case that is being shown on the page
 */
var populateData = (caseId) => {
  let data = getCaseById(caseId);
  let startDate = null;
  
  if (!data) {
    showError();
    showData();  
    return;
  }

  // format the date 
  if (data.jobStartDate) {
    let rawDate = new Date(data.jobStartDate);
    startDate = rawDate.getDate()+'/'+(rawDate.getMonth()+1)+'/'+rawDate.getFullYear(); 
  }
  // if data exists, display it on the page
  data.jobname ? (
    document.getElementsByClassName("jobname")[0].innerHTML = data.jobname, 
    document.getElementsByClassName("jobname")[1].innerHTML = data.jobname
  ) : ("", document.getElementById("company-title").style.display = "none");
  data.jobABN ? document.getElementById("abn").innerHTML = data.jobABN : ("", document.getElementById("abn-title").style.display = "none");
  data.jobACN ? document.getElementById("acn").innerHTML = data.jobACN : ("", document.getElementById("acn-title").style.display = "none");
  data.backgroundInfo ? document.getElementById("backgroundinfo").innerHTML = data.backgroundInfo : ("", document.getElementById("bg-info-title").style.display = "none");
  data.addressFull ? document.getElementById("jobtype").innerHTML = `<p><strong>Appointment Type: </strong>${data.jobTypeText}</p>`: "";
  startDate ? document.getElementById("appointmentdate").innerHTML = `<p><strong>Appointment Date: </strong>${startDate}</p>`: "";
  data.addressFull ? document.getElementById("address").innerHTML = `<p><strong>Address: </strong>${data.addressFull}</p>`: "";
  data.jobContactName ? document.getElementById("jobcontactname").innerHTML = data.jobContactName : "";
  data.jobContactEmail ? document.getElementById("jobcontactemail").innerHTML = `<a href='mailto:${data.jobContactEmail}'>${data.jobContactEmail}</a>` : "";
  data.jobContactNumber ? document.getElementById("jobcontactnumber").innerHTML = data.jobContactNumber : "";

  // add ey prefix to URL
  let insol6Link = data.callback;
  let index = insol6Link.indexOf("//") + 2;
  insol6Link = insol6Link.slice(0, index) + "ey." + insol6Link.slice(index);

  // populate button with link to Insol6 case details
	$(".textblock").append(
    `<button class="button more-info-cta creditor-login cta" type="button" style="text-decoration: none" onclick="window.open('${insol6Link}', '_blank')">
      <span><strong>View details in Creditor Portal</strong></span>
    </button>`    
	);
}

var populateDocuments = (data) => {
  let documentArray = data.field_case_document.split(", ");
  let fileIcon = "/images/file-text2.svg";

  $("#documents-section").append(
    `<h5><strong>Documents</strong></h5>
      <div class="search-list">
        <div class="list">
          <div class="inner">
            <ul id="documents-list" style="list-style: none"></ul>
          </div>
        </div>
      </div>
    `
  );

  for (let i = 0; i < documentArray.length; i++) {
    let title = documentArray[i].split(';').shift().trim();
    let filePath = documentArray[i].split(';').pop().trim();
    let fileExtension = filePath.split('.').pop().toLowerCase().trim();

    if (fileExtension === 'doc' || fileExtension === 'docx') {
      fileIcon = "/images/file-text2.svg";
    } else if (fileExtension === 'pdf') {
      fileIcon = "/images/file-pdf.svg";
    } else if (fileExtension === 'csv' || fileExtension === 'xls' || fileExtension === 'xlsx') {
      fileIcon = "/images/table.svg";
    }

    $("#documents-list").append(
      `<li class="items">
        <img class="file-icon" src=${fileIcon}>
        <a class="link" href="${process.env.ROOT_URL}${filePath}" target="_blank" download><p class="documentTitle">${title}</p></a>
      </li>`
    )
  }
}

/**
 * Once the data has been rendered, this function removes the loading icon and shows the content on the page.
 */
var showData = () => {
  $('.container-case-details').show();
  $('.footer-bottom').show();
  $('.footer-top').show();
  $('.loader').hide();
}

module.exports = CaseDetails;
