'use strict';

// Constructor
var HowCanWeHelp = function() {
  this.name = 'how-can-we-help';

  jQuery.ajax({
    url: `${process.env.ROOT_API}homepage-content`,
    type: "get",
    success: function(data){
      populateData(data[0]);
    },
    error: function() {
      $("#help-description").append("<p style='text-align:center'>Unable to load content. Please try again.</p>");
      showData();
    }
  });
};

/**
 * This function renders the 'How Can We Help' content on to the page.
 *
 * @param {Array} data The 'How Can We Help' array to be rendered.
 */
var populateData = (data) => {
  $("#help-title").html(data.help_title);
  $("#help-description").html(data.help_description);

  let image_raw_array = data.help_icon.split(",");
  let details_string = data.help_icon_details;

  let i = 0;
  while (i < image_raw_array.length) {

    let image_src_array = image_raw_array[i].split("\"");
    // Since 'lazy' in the dom, find url attr dynamically as find it and then
    // returning after it.
    let image_src = process.env.ROOT_URL + image_src_array.find(function (val, i) {
      if ((typeof image_src_array[i - 1] !== 'undefined')
        && (image_src_array[i - 1].indexOf('src=') !== -1)) {
        return true;
      }
      return false;
    });
    let image_attr = "";
    for (let j = 2; j < image_src_array.length; j++) {
        if (j === 3) {
            image_attr = image_attr.concat(`"${image_src_array[j]}"`);
        } else {
            image_attr = image_attr.concat(image_src_array[j]);
        }
    }

    let details_array = details_string.split("\r\n,")
    let details = details_array[i].trim();

    $("#help-list").append(
      `<li>
          <div class="img-container">
            <img src="${image_src}" ${image_attr}
          </div>
          <p>${details}</p>
      </li>`
    );
  i++;
  }

  // Add tags to the "How EY can help" section
  let tag_title_array = data.help_tag_title.split(";;");
  let tag_description_array = data.help_tag_description.split(";;");

  i = 0;
  while (i < tag_title_array.length) {
    let tag_title = tag_title_array[i].trim();
    let tag_description = tag_description_array[i].trim();

    $("#info-panel-section").append(
      `<div class="info-panel">
        <span>
          <h2 class="info-heading">${tag_title}</h2>
          <strong class="plus-icon">+  </strong>
        </span>
        <p class="info-paragraph">${tag_description}</p>
      </div>`
    );
    i++;
  }

  let isTablet = window.matchMedia("only screen and ((min-width: 546px) and (max-width: 760px))").matches;
  let isMobile = window.matchMedia("only screen and (max-width: 545px)").matches;
  let closedHeight = "60px";
  let openHeight = "180px";

  if (isTablet) {
      closedHeight = "45px";
      openHeight = "140px";
  }
  if (isMobile) {
      closedHeight = "40px";
      openHeight = "120px";
  }

  var panels = document.getElementsByClassName("info-panel");

  var openClosePanels = () => {
    for ( i = 0; i < panels.length; i++) {
        var content = panels[i].children[1];
        if (content.style.display === "block") {
            panels[i].style.height = closedHeight;
            content.style.display = "none";
        } else {
            panels[i].style.height = openHeight;
            content.style.display = "block"
        }
    }
  };

  for ( i = 0; i < panels.length; i++) {
      panels[i].addEventListener('click', openClosePanels, false);
  }
}

module.exports = HowCanWeHelp;
