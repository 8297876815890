'use strict';

// Constructor
var TheTeam = function() {
  this.name = 'the-team';

  jQuery.ajax({
    url: `${process.env.ROOT_API}bio-list`,
    type: "get",
    success: function(data){
      populateData(data);
      setTimeout(function() {
        showData();
        if (window.location.hash) {
          linkToSection();
        }
      }, 500);
    },
    error: function() {
      $("#people-list").append("<p style='text-align:center; font-size: 1.25em'>Unable to load team content. Please try again.</p>");
    }
  }); 
};

/**
 * If there is a '#' in the URL, this function will scroll to the specified section on the page.
 * 
 */
var linkToSection = () => {
  let section = window.location.hash;
  let element = document.querySelector(`${section}`);
  let header = document.querySelector('#header');
  let padding = header.getBoundingClientRect().bottom + (header.getBoundingClientRect().bottom*0.15);
  let yPosition = element.offsetTop - padding; 
  window.scrollTo({behavior: 'smooth', top: yPosition});
}

/**
 * This function renders team data on to the page.
 * 
 * @param {Array} data The array of team member objects to be rendered.
 */
var populateData = (data) => {
  let i = 0;
  // limit number of tiles shown to 20
  while (i < data.length && i < 20) {
    // extract image src
    let imageRawArray = data[i].image.split("\"");
    let imageElement = data[i].image;
    let imageSrc = imageElement.replace(/.*src="([^"]*)".*/, '$1').trim();
    let imageAttr = "";
    for (let j = 2; j < imageRawArray.length; j++) {
      if (j === 7) {
        imageAttr = imageAttr.concat(`"${imageRawArray[j]}"`);
      } else {
        imageAttr = imageAttr.concat(imageRawArray[j]);
      }
    }

    $("#people-list").append(
      `<div class="item">
        <a href="/people.html?id=${data[i].id}"></a>
        <img class="portrait" src='${process.env.ROOT_URL}${imageSrc}' ${imageAttr}>
        <div class="block"></div>
        <div class="item__overlay">
          <h3>${data[i].name}</h3>
          <p>${data[i].position}</p>
          <div class="item__body">
            <p>${data[i].short_description}</p>
            <p>${data[i].location}</p>
          </div>
        </div>
      </div>
      `
    );
    i++;
  }
}

/**
 * Once the data has been rendered, this function removes the loading icon and shows the content on the page.
 */
var showData = () => {
  $('.homepage-banner').show();
  $('.our-latest-thinking').show();
  $('.how-can-we-help').show();
  $('.the-team').show();
  $('.footer-bottom').show();
  $('.footer-top').show();
  $('.loader').hide();
}

module.exports = TheTeam;
